<template>
  <div class="layout__mantenimiento">
    <Header :disabledLogoLink="true" :hideSocials="true" :hideLang="true">
      <template #icon_toggle>
        <v-btn @click="toggleMenu" icon rounded class="btn__menu_mantenimiento mr-2">
          <v-icon color="white">mdi-menu</v-icon>
        </v-btn>
      </template>
    </Header>
    <div class="layout__sidebar" :class="{ 'layout__sidebar--dispose': sidebar }">
      <v-card height="100%" width="100%" class="mx-0">
        <v-navigation-drawer width="100%" permanent>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title class="text-h6">
                Mantenimiento
              </v-list-item-title>
              <v-list-item-subtitle>
                Gestor de Boleterías
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-divider></v-divider>
          <v-list dense nav>
            <v-list-item
              color="primary"
              v-for="item in menuMantenimiento"
              :key="item.title"
              link
              :to="item.route"
            >
              <v-list-item-icon>
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-navigation-drawer>
      </v-card>
    </div>
    <div class="layout__content">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Header from "../../components/Header.vue";

export default {
  components: { Header },
  data() {
    return {
      sidebar: false,
    };
  },
  computed: {
    ...mapGetters({
      menuMantenimiento: "getMenuMantenimiento"
    })
  },
  methods: {
    toggleMenu() {
      this.sidebar = !this.sidebar;
    },
  },
};
</script>

<style lang="scss">
.layout__mantenimiento {
  height: 100vh;
  display: grid;
  grid-template-columns: 300px 1fr;
  padding-top: 81px;
  .layout__sidebar {
    background: #fff;
  }
  .layout__content {
    padding: 1.5em;
    overflow-y: auto;
  }
  .layout__sidebar__item {
    display: flex;
    padding: 15px;
    gap: 8px;
    align-items: center;
    margin-bottom: 3px;
    box-shadow: 7px 5px 27px 4px rgba(0, 0, 0, 0.11);
  }

  .btn__menu_mantenimiento {
    display: none;
  }

  @media screen and (max-width: 800px) {
    grid-template-columns: 1fr;
    .layout__sidebar {
      position: fixed;
      left: 0;
      height: 100%;
      transition: all ease-in-out .25s;
      z-index: 50;
      &.layout__sidebar--dispose {
        left: -100%;
      }
    }
    .btn__menu_mantenimiento {
      display: inline-flex;
    }

  }

  @media screen and (max-width: 500px) {
    padding-top: 61px;
  }
}
</style>
